import { useState } from "react";

export default function Login(props) {
  const [username, setUsername] = useState('');
  const usernameChange = (e) => setUsername(e.target.value);
  const login = () => {
    if (username !== '') {
      localStorage.setItem('currentSession', username);
      props.onUserLoggedIn();
    }
  }
  const handleKeyDown = (e) => {
    // enter
    if (e.keyCode === 13) {
      login();
    }
  }
  return (
    <div>
      <div>
          <input type={'text'} onKeyDown={handleKeyDown} placeholder='Username' autoFocus tabIndex='1' value={username} id='username' onChange={usernameChange}></input>
      </div>
      <div>
          <button onClick={login}>Login</button>
      </div>
      </div>
  );
}