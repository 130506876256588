import './App.css';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { calandiPost, getEmptyOwnerValue, processSelectedText } from './utils/CalandiUtils';
import Login from './user/Login';
import Owner from './owner/Owner';
import OwnerInput from './owner/OwnerInput';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [docData, setDocData] = useState({pages: []});
  const [curPage, setCurPage] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [status, setStatus] = useState('');
  const [originalStatus, setOriginalStatus] = useState('');
  const [curX, setCurX] = useState(0);
  const [curY, setCurY] = useState(0);
  const [availWidth, setAvailWidth] = useState(210);
  const [availHeight, setAvailHeight] = useState(297);
  const [ownerList, setOwnerList] = useState([{...getEmptyOwnerValue()}]);
  const [originalOwnerList, setOriginalOwnerList] = useState([]);
  const mainViewRef = useRef(null);
  const curPageRef = useRef(curPage);
  const docDataRef = useRef(docData);
  const companyIdRef = useRef(companyId);
  const ownerListRef = useRef(ownerList);
  const originalOwnerListRef = useRef(originalOwnerList);
  const statusRef = useRef(status);
  const originalStatusRef = useRef(originalStatus);
  const setTimeoutInfoRef = useRef(null);
  const setTimeoutErrorRef = useRef(null);
  const [currentOwnerIndex, setCurrentOwnerIndex] = useState(0);
  const [imgScale, setImgScale] = useState(1);
  const [imgStyle, setImgStyle] = useState({});
  const [divStyle, setDivStyle] = useState({});
  const [highlightDivStyle, setHighlightDivStyle] = useState({});
  const [minScale, setMinScale] = useState(1);
  const [blockText, setBlockText] = useState('');
  const [infoMsg, setInfoMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showOwnerList, setShowOwnerList] = useState(false);
  const [statusList, setStatusList] = useState('');
  useLayoutEffect(() => {
    if (mainViewRef.current === null) {
      return;
    }
    setAvailWidth(mainViewRef.current.offsetWidth * 5 / 6);
    setAvailHeight(mainViewRef.current.offsetHeight - 30);
  }, [loggedIn]);
  useEffect(() => {
    async function getDoc(url){
      const data = await calandiPost(url, {'currentCompanyId': companyIdRef.current});
      if (data === null || data.company.id === undefined) {
        if (url === '/public/previous') {
          setInfoMsg('Dies ist das erste Unternehmen');
        } else if (url === '/public/next') {
          setInfoMsg('Dies ist das letzte Unternehmen');
        }
        return;
      }
      setCompanyId(data.company.id);
      let companyStatus = data.company.status != null ? data.company.status : '';
      setStatus(companyStatus);
      setOriginalStatus(companyStatus);
      setCurPage(0);
      let companyOwnerList;
      if (data.ownerList.length === 0) {
        companyOwnerList = [{...getEmptyOwnerValue()}];
      } else {
        companyOwnerList = data.ownerList;
      }
      setOwnerList(companyOwnerList);
      setOriginalOwnerList(companyOwnerList);
      setCurrentOwnerIndex(0);
      const jsonContentUrl = data.company.jsonContentUrl;
      try {
        setDocData(await (await fetch(jsonContentUrl)).json());
      } catch (error) {
        setDocData({pages: []});
        setErrorMsg("Beim Abrufen des OCR-Ergebnisses für die Unternehmens-ID " + data.company.id + " ist ein Fehler aufgetreten.");
      }
    }
    async function getStatusList() {
      setStatusList(await calandiPost('/public/get/status/list', {}));
    }
    function getPage(delta) {
      if (docDataRef.current.pages.length === 0) {
        return;
      }
      if (delta > 0 && curPageRef.current >= docDataRef.current.pages.length - 1) {
        setInfoMsg('Dies ist die letzte Seite');
        setCurPage(docDataRef.current.pages.length - 1);
      } else if (delta < 0 && curPageRef.current <= 0) {
        setInfoMsg('Dies ist die erste Seite');
        setCurPage(0);
      } else {
        setCurPage(curPageRef.current + delta);
      }
    }

    async function handleKeyDown(e) {
      if ([38, 40].includes(e.keyCode)) {
        if (window.handlingKeyDown) {
          return;
        } else {
          window.handlingKeyDown = true;
        }
      }
      // up (previous pdf)
      if (e.keyCode === 38) {
        // We want to make sure that the following actions happen in order, so we use await
        await save();
        await getDoc('/public/previous');
        window.handlingKeyDown = false;
      }
      // down (next pdf)
      // We want to make sure that the following actions happen in order, so we use await
      else if (e.keyCode === 40) {
        await save();
        await getDoc('/public/next');
        window.handlingKeyDown = false;
      }
      // esc (previous page)
      else if (e.keyCode === 27) {
        getPage(-1);
      }
      // back quote (next page)
      else if (e.keyCode === 192) {
        getPage(1);
      }
    }
    let currentUsername = localStorage.getItem('currentSession');
    if (currentUsername !== null && currentUsername !== undefined) {
      setLoggedIn(true);
    }
    if (loggedIn) {
      window.addEventListener('keydown', handleKeyDown);
      getDoc('/public/start');
      getStatusList();
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [loggedIn]);
  useEffect(() => {
    if (blockText === "") {
      setHighlightDivStyle({});
    }
  }, [blockText]);
  useEffect(() => {
    curPageRef.current = curPage;
  }, [curPage]);
  useEffect(() => {
    docDataRef.current = docData;
  }, [docData]);
  useEffect(() => {
    companyIdRef.current = companyId;
  }, [companyId]);
  useEffect(() => {
    ownerListRef.current = ownerList;
  }, [ownerList]);
  useEffect(() => {
    originalOwnerListRef.current = originalOwnerList;
  }, [originalOwnerList]);
  useEffect(() => {
    statusRef.current = status;
  }, [status]);
  useEffect(() => {
    originalStatusRef.current = originalStatus;
  }, [originalStatus]);
  useEffect(() => {
    setImgStyle(
      {
        width: availWidth / imgScale + 'px',
        height: availHeight / imgScale + 'px',
        objectFit: 'none',
        objectPosition: curX + 'px ' + curY + 'px',
      }
    );
    setDivStyle({
      position: 'relative',
      width: availWidth + 'px',
      height: availHeight + 'px',
      transform: 'scale(' + imgScale + ')',
      translate: (imgScale - 1) * (availWidth / 2) + 'px ' + (imgScale - 1) * (availHeight / 2) + 'px',
    })
  }, [curX, curY, imgScale, availWidth, availHeight]);
  useEffect(() => {
    if (docData.pages.length === 0) {
      return;
    }
    const realDimension = docData.pages[curPage].dimension;
    const realWidth = realDimension.width;
    const realHeight = realDimension.height;
    const realRatio = realWidth / realHeight;
    let scale;
    if (realRatio < availWidth / availHeight) {
      scale = availHeight / realHeight;
    } else {
      scale = availWidth / realWidth;
    }
    setMinScale(scale);
    setImgScale(scale);
    setCurX(0);
    setCurY(0);
    setBlockText('');
  }, [docData, curPage, availWidth, availHeight]);
  useEffect(() => {
    if (infoMsg !== '') {
      if (setTimeoutInfoRef.current) {
        clearTimeout(setTimeoutInfoRef.current);
      }
      setTimeoutInfoRef.current = setTimeout(() => {
        setInfoMsg('');
      }, 3000);
      setErrorMsg('');
    }
    if (errorMsg !== '') {
      if (setTimeoutErrorRef.current) {
        clearTimeout(setTimeoutErrorRef.current);
      }
      setTimeoutErrorRef.current = setTimeout(() => {
        setErrorMsg('');
      }, 3000);
      setInfoMsg('');
    }
  }, [infoMsg, errorMsg]);
  const handleClick = (e) => {
    setBlockText('');
  }
  const handleWheel = (e) => {
    let scale = imgScale + e.deltaY * -0.01;
    if (scale < imgScale) {
      if (scale < minScale) {
        if (imgScale > minScale) {
          setImgScale(minScale);
          setCurX(0);
          setCurY(0);
        }
        return;
      }
      setImgScale(scale);
    } else {
      setImgScale(scale);
    }
  }
  const onUserLoggedIn = () => {
    setLoggedIn(true);
  }
  const renderOwnerList = () => {
    const renderList = []
    for(let i = 0; i < ownerList.length; i++) {
      renderList.push(<Owner key={i} index={i} data={{...ownerList[i]}} handleChange={handleChange} editOwner={editOwner} deleteOwner={deleteOwner} />)
    }
    return renderList;
  }
  const renderBlocks = () => {
    if (docData.pages.length === 0) {
      return [];
    }
    const renderList = [];
    const page = docData.pages[curPage];
    let blocks = page.blocks;
    const text = docData.text;
    const vertices = page.layout.boundingPoly.vertices;
    let i = 0;
    for (const block of blocks) {
      const textSegment = block.layout.textAnchor.textSegments[0];
      const normalizedVertices = block.layout.boundingPoly.normalizedVertices;
      const x = vertices[2].x;
      const y = vertices[2].y;
      const top = Math.floor(normalizedVertices[0].y * y + curY);
      const bottom = Math.floor(normalizedVertices[2].y * y + curY);
      const left = Math.floor(normalizedVertices[0].x * x + curX);
      const right = Math.floor(normalizedVertices[2].x * x + curX);
      const cords = left + "," + top + "," + right + "," + bottom;
      const curBlockText = text.substring(textSegment.startIndex, textSegment.endIndex);
      renderList.push(<area alt={curBlockText} key={i} data-top={top} data-left={left} data-bottom={bottom} data-right={right} data-ocr={curBlockText} shape='rect' coords={cords} href='#' onClick={clickMap} />)
      i++;
    }
    return renderList;
  }
  const clickMap = (e) => {
      e.preventDefault();
      let selectedText = e.target.getAttribute('data-ocr');
      setBlockText(selectedText);
      setOwnerList([...ownerList.slice(0, currentOwnerIndex), { ...processSelectedText(selectedText, ownerList[currentOwnerIndex]) }, ...ownerList.slice(currentOwnerIndex + 1)]);
      const top = e.target.getAttribute('data-top');
      const bottom = e.target.getAttribute('data-bottom');
      const left = e.target.getAttribute('data-left');
      const right = e.target.getAttribute('data-right');
      setHighlightDivStyle({
        position: 'absolute',
        border: 'solid 1px red',
        width: (right - left) + 'px',
        height: (bottom - top) + 'px',
        top: top + 'px',
        left: left + 'px',
      });
      e.stopPropagation();
  }
  const newOwner = () => {
    let noOfOwners = ownerList.length;
    setCurrentOwnerIndex(noOfOwners);
    setOwnerList([...ownerList, {...getEmptyOwnerValue()}]);
  }
  const save = async () => {
    let statusChanged = statusRef.current != originalStatusRef.current;
    let ownerInfoChanged = (ownerListRef.current.length != originalOwnerListRef.current.length) || !ownerListRef.current.every(
      (o, i) => {
        let o1 = originalOwnerListRef.current[i];
        return o.ownerType == o1.ownerType
          && (o.ownerType == 'COMPANY' ? (o.companyName == o1.companyName) : (o.title == o1.title && o.firstName == o1.firstName && o.lastName == o1.lastName && o.dateOfBirth == o1.dateOfBirth))
          && o.address == o1.address
          && o.city == o1.city
          && o.country == o1.country
          && o.zip == o1.zip
          && o.share == o1.share
      })
    if (!ownerInfoChanged && !statusChanged) {
      return;
    }
    if (companyIdRef.current === null || companyIdRef.current === undefined) {
      setErrorMsg('Keine Firmenangaben');
      return;
    }
    let dateOfBirthIsOk = true;
    ownerListRef.current.every(owner => {
      if (owner.dateOfBirth !== null && owner.dateOfBirth.trim() !== '') {
        let found = owner.dateOfBirth.match(/^\d{2}\.\d{2}\.\d{4}$/);
        if (found === null || found.length === 0) {
          dateOfBirthIsOk = false;
          return false;
        }
      }
      return true;
    });
    if (!dateOfBirthIsOk) {
      setErrorMsg('Nicht alle Geburtsdaten haben das Format tt.mm.jjjj');
      return;
    }
    const data = await calandiPost('/public/save', {
      companyId: companyIdRef.current,
      status: (statusChanged ? statusRef.current : null),
      ownerList: (ownerInfoChanged ? ownerListRef.current.filter(o => !o.isFake) : null)
    })
    if (data !== null && data.ids !== undefined) {
      setInfoMsg('Gerettet');
      let newOwnerList = [...ownerListRef.current];
      for (let i = 0; i < ownerListRef.current.length; i++) {
        if (ownerListRef.current[i].share !== data.shares[i]) {
          newOwnerList = [...newOwnerList.slice(0, i), { ...newOwnerList[i], 'share': data.shares[i] }, ...newOwnerList.slice(i + 1)];
        }
      }
      setOwnerList(newOwnerList);
      setOriginalOwnerList(newOwnerList);
    } else {
      setErrorMsg('Konnte nicht speichern')
    }
  }
  const handleChange = (index, prop) => (event) => {
    setOwnerList([...ownerList.slice(0, index), { ...ownerList[index], [prop]: event.target.value, isFake: false }, ...ownerList.slice(index + 1)]);
  };
  const handleKeyDownForOwnerInput = (e) => {
    if (e.keyCode === 192) {
      e.preventDefault();
      return false;
    }
    return true;
  }
  const deleteOwner = (index) => (event) => {
    if (window.confirm("Want to delete?")) {
      if (index === currentOwnerIndex) {
        setCurrentOwnerIndex(0);
      } else if (index < currentOwnerIndex) {
        setCurrentOwnerIndex(currentOwnerIndex - 1);
      }
      if (ownerList.length > 1) {
        setOwnerList([...ownerList.slice(0, index), ...ownerList.slice(index + 1)]);
      } else {
        setOwnerList([{...getEmptyOwnerValue()}]);
        setInfoMsg('Kein Eigentümer mehr');
      }
    }
  }
  const editOwner = (index) => (event) => {
    setCurrentOwnerIndex(index);
  }
  const statusChange = (e) => {
    setStatus(e.target.value);
  }
  const mouseMove = (e) => {
    if (e.ctrlKey) {
      setCurX(curX + e.movementX);
      setCurY(curY + e.movementY);
    }
  }
  const toggleOwnerList = (onOff) => () => {
    if (onOff === 'on') {
      setShowOwnerList(true);
    } else if (onOff === 'off') {
      setShowOwnerList(false);
    }
  }
  const logOut = () => {
    localStorage.removeItem("currentSession");
    setDocData({pages: []});
    setCurPage(0);
    setCompanyId(0);
    setOwnerList([{...getEmptyOwnerValue()}]);
    setCurrentOwnerIndex(0);
    setShowOwnerList(false);
    setLoggedIn(false);
  }
  const renderStatusList = () => {
    const renderList = [];
    const lst = statusList.split("::");
    for(let i = 0; i < lst.length; i++) {
      renderList.push(<option label={lst[i]} value={lst[i]} key={i} />)
    }
    return renderList;
  }
  return (
    <>
    { loggedIn ?
    <div style={{display: 'flex', flexDirection: 'column', position: 'fixed', height: '100%', width: '100%'}}>
      <main style={{flexGrow: 1}} ref={mainViewRef}>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 5fr', gridTemplateRows: '0px 80px auto 30px'}}>
          <section className='notification'>
            <span className='infoMsg'>{infoMsg}</span>
          </section>
          <section className='notification'>
            <span className='errorMsg'>{errorMsg}</span>
          </section>
          <section style={{gridRow: 1, gridColumn: 2, position: 'relative'}}>
            {showOwnerList ?
            <div style={{position: 'absolute', top: '0px', left: '0px', zIndex: 1, width: '100%'}}>
              <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Company name</th>
                    <th>Title</th>
                    <th>Street and number</th>
                    <th>Zip</th>
                    <th>City</th>
                    <th>Date of birth</th>
                    <th>Country</th>
                    <th>Share</th>
                    <td><button onClick={newOwner}>New owner</button></td>
                    <td><button className='primaryButton' onClick={toggleOwnerList('off')}>Save space</button></td>
                  </tr>
                </thead>
                <tbody>
                {renderOwnerList()}
                </tbody>
              </table>
            </div>
            :
            <></>
            }
          </section>
          <section onClick={handleClick} onWheel={handleWheel} onMouseMove={mouseMove} style={{gridRow: '2 / 5', gridColumn: 2}}>
            <div style={divStyle}>
              <map name='ocr'>
                {renderBlocks()}
              </map>
              <img useMap='#ocr' style={imgStyle} alt='' src={ 0 <= curPage && curPage < docData.pages.length ? 'data:image/png;base64, ' + docData.pages[curPage].image.content : ''} />
              <div style={highlightDivStyle}></div>
            </div>
          </section>
          <section style={{gridRow: 2, gridColumn: 1}}>
            <div>
              <strong>{companyId}</strong>
            </div>
            <div>
              <label htmlFor='status'>Status</label>
              <select id='status' value={status} onChange={statusChange}>
                {renderStatusList()}
              </select>
            </div>
            <div>
              <button className='primaryButton' onClick={save}>Save</button>
            </div>
            <div>
              <span><button onClick={logOut}>Logout {localStorage.getItem('currentSession')}</button></span>
            </div>
          </section>
          <section style={{gridRow: 3, gridColumn: 1, position: 'relative'}}>
            <div className='ownerInput' style={{width: '100%', position: 'absolute', top: '25px', left: '3px'}}>
              <OwnerInput noOwners={ownerList.filter(o => !o.isFake).length} index={currentOwnerIndex} data={ownerList[currentOwnerIndex] !== undefined ? {...ownerList[currentOwnerIndex]} : {...getEmptyOwnerValue()}} viewAll={toggleOwnerList('on')} handleChange={handleChange} handleKeyDown={handleKeyDownForOwnerInput} newOwner={newOwner} />
              <div>
                <textarea rows={10} style={{width: '100%'}} readOnly={true} defaultValue={blockText}></textarea>
              </div>
            </div>
          </section>
          <aside style={{gridRow: 4, gridColumn: 1, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 2fr 2fr'}}>
            <div>
              {0 <= curPage && curPage < docData.pages.length ? (curPage + 1) : 0}/{docData.pages.length}
            </div>
          </aside>
        </div> 
      </main>
    </div> :
    <section>
      <Login onUserLoggedIn={onUserLoggedIn} />
    </section>}
    </>
  );
}

export default App;
