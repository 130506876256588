export default function OwnerInput(props) {
  return (
    <div style={{display: 'grid', gap: '10px',}}>
      <select tabIndex={1} id={'ownerType'} value={props.data.ownerType != 'COMPANY' ? 'PERSON' : 'COMPANY'}
              onChange={(e) => {
                props.handleChange(props.index, 'ownerType')(e);
              }}
      >
        <option value={'PERSON'}>PERSON</option>
        <option value={'COMPANY'}>COMPANY</option>
      </select>
      {props.data.ownerType != 'COMPANY' && <input type={'text'} placeholder={'Vornamen'} tabIndex={2} id={'firstName'} value={props.data.firstName || ''} onChange={props.handleChange(props.index, 'firstName')} onKeyDown={props.handleKeyDown}/>}
      {props.data.ownerType != 'COMPANY' && <input type={'text'} placeholder={'Nachname'} tabIndex={3} id={'lastName'} value={props.data.lastName || ''} onChange={props.handleChange(props.index, 'lastName')} onKeyDown={props.handleKeyDown}/>}
      {props.data.ownerType == 'COMPANY' && <input type={'text'} placeholder={'Firmenname'} tabIndex={2} id={'companyName'} value={props.data.companyName || ''} onChange={props.handleChange(props.index, 'companyName')} onKeyDown={props.handleKeyDown}/>}
      {props.data.ownerType != 'COMPANY' && <input type={'text'} placeholder={'Titel'} tabIndex={1000} id={'title'} value={props.data.title || ''} onChange={props.handleChange(props.index, 'title')} onKeyDown={props.handleKeyDown}/>}
      <input type={'text'} placeholder={'Straße und Hausnummer'} tabIndex={4} id={'address'} value={props.data.address || ''} onChange={props.handleChange(props.index, 'address')} onKeyDown={props.handleKeyDown}/>
      <input type={'text'} placeholder={'Plz'} tabIndex={5} id={'zip'} value={props.data.zip || ''} onChange={props.handleChange(props.index, 'zip')} onKeyDown={props.handleKeyDown}/>
      <input type={'text'} placeholder={'Stadt'} tabIndex={6} id={'city'} value={props.data.city || ''} onChange={props.handleChange(props.index, 'city')} onKeyDown={props.handleKeyDown}/>
      {props.data.ownerType != 'COMPANY' && <input type={'text'} placeholder={'Datum der Geburt'} tabIndex={7} id={'dateOfBirth'} value={props.data.dateOfBirth || ''} onChange={props.handleChange(props.index, 'dateOfBirth')} onKeyDown={props.handleKeyDown}/>}
      <input type={'text'} placeholder={'Land'} tabIndex={7} id={'country'} value={props.data.country || ''} onChange={props.handleChange(props.index, 'country')} onKeyDown={props.handleKeyDown}/>
      <div>
        <button onClick={props.newOwner} tabIndex={9}>New owner</button>&nbsp;
        <button className='primaryButton' onClick={props.viewAll}>View {props.noOwners}</button>
      </div>
    </div>
  );
}