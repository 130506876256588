export default function Owner(props) {
  return (
    <tr>
      <td>
        {props.data.firstName || ''}
      </td>
      <td>
        {props.data.lastName || ''}
      </td>
      <td>
        {props.data.companyName || ''}
      </td>
      <td>
        {props.data.title || ''}
      </td>
      <td>
        {props.data.streetAndNumber || ''}
      </td>
      <td>
        {props.data.zip || ''}
      </td>
      <td>
        {props.data.city || ''}
      </td>
      <td>
        {props.data.dateOfBirth || ''}
      </td>
      <td>
        {props.data.country || ''}
      </td>
      <td>
        <button onClick={props.deleteOwner(props.index)}>Delete</button>
      </td>
      <td><button onClick={props.editOwner(props.index)}>Edit</button></td>
    </tr>
  );
}