export async function calandiPost(url, paramObj) {
    let baseUrl;
    if (process.env.NODE_ENV === 'production') {
        baseUrl = 'https://1.firma-verkauf.ca-one.de';
    } else {
        baseUrl = 'http://localhost:8080';
    }
    let currentSession = localStorage.getItem('currentSession');
    try {
        const response = await fetch(
            baseUrl + url,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...paramObj, ...{ 'username': currentSession } }),
            }
        );
        const data = await response.json();
        if (data.status === 'success') {
            return data.data
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
}
export function getEmptyOwnerValue() {
    return {
      ownerType: 'PERSON',
      firstName: '',
      lastName: '',
      companyName: '',
      title: '',
      address: '',
      zip: '',
      city: '',
      dateOfBirth: '',
      country: 'Germany',
      share: 0,
      isFake: true
    }
}
export function processSelectedText(selectedText, owner) {
    let currentOwner = {...owner, isFake: false};
    const prefixes = ['herr', 'frau', 'dr\\.', 'dipl\\.ing\\.', ''];
    const titles = ['dr\\.', 'dipl\\.ing\\.'];
    prefixes.every(prefix => {
      if (currentOwner.ownerType === 'COMPANY') {
        return true;
      }
      let re = new RegExp(prefix, 'i');
      let found = selectedText.match(re);
      if (found === null || found.length === 0) {
        return true;
      }
      const dateOfBirthPattern = '(\\d{1,}[^a-zäöüß0-9]+[a-zäöüß0-9]{1,}[^a-zäöüß0-9]+\\d{1,})';
      const patterns = ['.*[\\D]+' + dateOfBirthPattern, ''];
      let matched = false;
      patterns.every(pattern => {
        re = new RegExp('(' + prefix + ')\\s*([a-zäöüß]+[a-z0-9äöüß\\-]*)[ ,\\n\\r]+([a-zäöüß]+[a-z0-9äöüß\\-]*)' + pattern, 'i');
        found = selectedText.match(re);
        if (found !== null && found.length > 0) {
          if (titles.indexOf(prefix) > -1 && (currentOwner.title === null || currentOwner.title.trim() === '')) {
            currentOwner.title = found[1];
          }
          if (currentOwner.firstName === null || currentOwner.firstName.trim() === '') {
            currentOwner.firstName = found[2];
          }
          if (currentOwner.lastName === null || currentOwner.lastName.trim() === '') {
            currentOwner.lastName = found[3];
          }
          if (pattern !== '' && (currentOwner.dateOfBirth === null || currentOwner.dateOfBirth.trim() === '')) {
            currentOwner.dateOfBirth = found[4];
          }
          matched = true;
          return false;
        } else if (pattern === '') {
          re = new RegExp('^' + dateOfBirthPattern);
          let found = selectedText.match(re);
          if (found !== null && found.length > 0) {
            if (currentOwner.dateOfBirth === null || currentOwner.dateOfBirth.trim() === '') {
              currentOwner.dateOfBirth = found[1];
            }
            matched = true;
            return false;
          }
        }
        return true;
      });
      if (matched) {
        return false;
      }
      return true;
    });
    let re = /(\d{5}) ([a-zA-ZäöüÄÖÜß]+)/i;
    let found = selectedText.match(re);
    if (found !== null && found.length > 0) {
      if (currentOwner.zip === null || currentOwner.zip.trim() === '') {
        currentOwner.zip = found[1].trim();
      }
      if (currentOwner.city === null || currentOwner.city.trim() === '') {
        currentOwner.city = found[2].trim();
      }
    }
    return currentOwner;
  }